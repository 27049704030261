import { chain, configureChains, createClient, WagmiConfig } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { ContractRead } from './contractRead';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { Chain } from 'wagmi';
import { useAddress } from "@thirdweb-dev/react";
import { ContractWrite } from "./contractWrite";

const bsctestnet = {
  id: 97,
  name: 'BSC Testnet',
  network: 'BSCT',
  testnet: true
};

const { chains, provider } = configureChains(
  [bsctestnet],
  [
    jsonRpcProvider({
      rpc: (chain) => ({
        http: `https://data-seed-prebsc-1-s1.binance.org:8545/`
      })
    })
  ]
);

export function WagmiApp() {
  const client = createClient({
    autoConnect: false,
    provider
  });
  const address = useAddress();

  return (
    <WagmiConfig client={client}>
      <p>Wagmi app</p>
      <ContractRead />
      <ContractWrite />
    </WagmiConfig>
  );
}
