import { useAccount, useConnect, useContractRead, useDisconnect } from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';

import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";

export function ConnectButton() {
  const { address, isConnected } = useAccount();
  const { connect } = useConnect({
    connector: new WalletConnectConnector({
      options: {
        qrcode: true
      }
    })
  });
  const { disconnect } = useDisconnect();
  return (
    <div>
      {isConnected ? (
        <div>
          <p>Wagmi wallet Connected to {address}</p>
          <button onClick={() => disconnect()}>Wagmi Disconnect Wallet</button>
        </div>
      ) : (
        <button onClick={() => connect()}>Wagmi Connect</button>
      )}
    </div>
  );
}
