import sri from '../sri.jpeg';

const black = {
  color: "black",
};

//Home page with socials
const Home = () => {
  return (
    <div className="App">
      <header className="App-header">
        <br />
        <img src={sri} className="App-logo" alt="profile pic" />
        <p style={{ ...black }}>Sri Krishna Mannem</p>
        <a
          className="App-link"
          href="https://www.linkedin.com/in/sri-krishna-mannem/"
          target="_blank"
          rel="noopener noreferrer">
          Linkedin
        </a>
        <br />
        <a
          className="App-link"
          href="https://t.me/srik410"
          target="_blank"
          rel="noopener noreferrer">
          Telegram
        </a>
        <br />
        <a
          className="App-link"
          href="https://www.instagram.com/srikrishnamannem/"
          target="_blank"
          rel="noopener noreferrer">
          Instagram
        </a>
        <br />
        <a
          className="App-link"
          href="https://github.com/Msrikrishna"
          target="_blank"
          rel="noopener noreferrer">
          Github
        </a>
        <br />
        <p style={{ color: 'green' }}>
          If you are interested in having a chat, please book a slot down below
        </p>
        <iframe
          title="calender"
          src="https://calendar.google.com/calendar/appointments/AcZssZ1kd_3kvFHUnjQxrSyt2pUqYPDCWD3gYEDcX0w=?gv=true"
          width="95%"
          height="600"
          frameBorder="0"></iframe>
      </header>
    </div>
  );
};

export default Home;
