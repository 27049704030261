import { InjectedConnector } from 'wagmi/connectors/injected';
import myABI from '../abi/Registry.json';
import { useState } from 'react';
import { disconnect } from 'wagmi/actions';
import { useAccount, useConnect, useContract, useContractRead } from 'wagmi';
import { ConnectButton } from "./connectButton";

const contractAddress = '0x999DD49FeFdC043fDAC4FE12Bb1e4bb31cB4c47B';

//Reads the FeedRegistry contract
export function ContractRead() {
  const { address, isConnected } = useAccount();
  const { data, isError, isLoading } = useContractRead({
    addressOrName: contractAddress,
    contractInterface: myABI,
    functionName: 'owner'
  });

  return (
    <div>
      <b> Reading a contract at a given address</b>

      <p>
        Owner at address {contractAddress} is {data}
      </p>
    </div>
  );
}
