import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import Web3Wrapper from './pages/web3';
import { ContractRead, WagmiApp } from "./web3helpers/wagmi";

const black = {
  color: 'black'
};

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/thirdweb" element={<Web3Wrapper />} />
        <Route path="/wagmi" element={<WagmiApp />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
