import { InjectedConnector } from 'wagmi/connectors/injected';
import myABI from '../abi/Registry.json';
import { useEffect, useState } from 'react';
import {
  useAccount,
  useConnect,
  useContract,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite
} from 'wagmi';
import { ConnectButton } from './connectButton';

//Reads the FeedRegistry contract
export function ContractWrite() {
  const [writeStatus, setWriteStatus] = useState(null);
  const { data, error, isLoading, isSuccess, write } = useContractWrite({
    mode: 'recklesslyUnprepared',
    addressOrName: '0x999DD49FeFdC043fDAC4FE12Bb1e4bb31cB4c47B',
    contractInterface: myABI,
    functionName: 'acceptOwnership',
    onSettled(data, error) {
      setWriteStatus(data + error);
      console.log('Settled', { data, error });
    }
  });

  useEffect(() => {
    error && console.log(error); // To print the contractWriteError object
  }, [error]);

  // useEffect(() => {
  //   setState(data);
  // }, [isLoading, isSuccess]);

  return (
    <div>
      <ConnectButton />
      <p>
        <b> Calling acceptOwnership in FeedRegistry contract</b>
      </p>
      <button onClick={() => write()}>Write</button>
      <p> </p>
      {/* <button onClick={() => write?.()}> */}
      {isLoading ? <div>Loading write data</div> : <p></p>}
      {writeStatus != null ? <div>Transaction: {JSON.stringify(writeStatus)}</div> : <p></p>}
    </div>
  );
}
