import { ChainId, ConnectWallet, ThirdwebProvider, useAddress, useSDK } from '@thirdweb-dev/react';
import { useEffect, useState } from 'react';
import { ContractRead, WagmiApp } from "../web3helpers/wagmi";

const black = {
  color: 'black'
};

//Uses ThirdWeb as a wrapper
export default function Web3Wrapper() {
  return (
    <ThirdwebProvider
      desiredChainId={ChainId.BinanceSmartChainTestnet}
      chainRpc={{
        [ChainId.BinanceSmartChainTestnet]: 'https://data-seed-prebsc-1-s1.binance.org:8545/'
      }}
      supportedChains={[ChainId.BinanceSmartChainTestnet]}
      sdkOptions={{
        gasSettings: { maxPriceInGwei: 500, speed: 'fast' },
        readonlySettings: {
          chainId: ChainId.BinanceSmartChainTestnet,
          rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/'
        }
      }}>
      <Web3App></Web3App>
    </ThirdwebProvider>
  );
}

//Use ThirdWeb
function Web3App() {
  const address = useAddress();
  const sdk = useSDK();
  const [balanceDetails, setBalanceDetails] = useState({
    tokenName: '',
    symbol: '',
    decimals: '',
    balance: ''
  });
  useEffect(() => {
    sdk
      .getBalance(address)
      .then((r) =>
        setBalanceDetails((prevState) => {
          return {
            tokenName: r.name,
            symbol: r.symbol,
            decimals: r.decimals,
            balance: r.displayValue
          };
        })
      )
      .catch(console.error);
  });

  //Some abi call
  useEffect(() => {
  });


  return (
    <div>
      <h1>Want to earn some NFT? Follow the steps below</h1>
      <div>
        <ConnectWallet />
      </div>

      {address ? (
        <p>
          <p>You are now connected to Wallet with address: {address}</p>
          Token name <b>{balanceDetails.tokenName}</b> with symbol <b>{balanceDetails.symbol}</b>{' '}
          has balance <b>{balanceDetails.balance}</b>{' '}
        </p>
      ) : (
        <p></p>
      )}
      {/* {error ? <p>Error reading from contract...</p> : <p> No error </p>} */}
      {/* {isLoading ? <p>Loading...</p> : <p>Reading BNB/USD price from Registry {data}</p>} */}
    </div>
  );
}
